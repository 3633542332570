#terminal {
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    padding-top: 25px;
    background: white;
    margin-left: 90px;
}

#terminal-content {
    background-color: var(--bc-3);
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
    height: 240px;
    overflow-y: scroll;
}

#terminal-content p {
    font-size: var(--fs-s);
    color: var(--fc-1);
    line-height: 30px;
    margin: 0;
    margin-bottom: 5px;
}

#terminal-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#terminal-content ul li {
    display: flex;
}

#terminal-content ul li h3 {
    list-style: none;
    padding: 0;
    font-size: var(--fs-s);
}

.code-text {
    color: var(--fc-1);
    font-weight: normal;
    margin: 0;
}

.code-arrow-right,
.code-arrow-left {
    font-weight: bold;
    margin: 0 8px 0 0;
}

.code-arrow-right {
    color: var(--grey);
}

.code-arrow-left {
    color: var(--grey);
}

#code-arrow-blue {
    color: var(--fc-3);
}


/*mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    #terminal {
        margin-left: var(--margin-m);
        margin-right: var(--margin-m);
    }
}


/*med*/

@media only screen and (max-width: 1200px) and (min-width: 769px) {
    #terminal {
        margin-left: var(--margin-dm);
        margin-right: var(--margin-dm);
    }
}