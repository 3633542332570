#ResumeBuilder {
    min-height: 100vw;
    min-height: 100vh;
    background-color: #f5f5f5;
    position: absolute;
    top: -100%;
    left: -100%;
    z-index: -1;
}

#resume {
    width: 8.5in;
    height: 14in;
    margin: 0;
    position: relative;
}

.resume-side-offset {
    margin: 0 50px;
}


/*RESUME HEADER*/

.resume-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 45px;
    padding-top: 50px;
}

.resume-head h1 {
    display: flex;
    font-size: var(--fs-xl);
    font-weight: 700;
    margin: 0 50px 0 0;
}

.resume-contact {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: right;
}

.resume-contact h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: var(--fs-s);
}

.resume-contact hr {
    width: 100%;
    height: 7px;
    background-color: black;
}


/*RESUME SECTION*/

.resume-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.resume-section-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 120px;
    margin-right: 50px;
    min-width: 104px;
}

.resume-section-title h3 {
    margin: 0 0 0 10px;
    padding: 0;
    font-size: var(--fs-s);
}

.resume-section-splitter {
    width: 100%;
    background-color: var(--grey);
    border: none;
    height: 1px;
}

.resume-section-content {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
}

.resume-section-content-item {
    margin: 10px 0;
}

.resume-job-company {
    margin: 0;
    padding: 0;
    font-size: var(--fs-m);
}

.resume-job-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.resume-job-title h3 {
    margin: 0;
    padding: 0;
    font-size: var(--fs-xs);
    font-weight: normal;
}

.resume-job-desc {
    margin: 8px 0 0 50px;
    padding: 0;
    font-size: var(--fs-s);
    list-style: disc;
}

.resume-job-desc li {
    font-size: var(--fs-s);
    font-weight: 400;
    margin-bottom: 1px;
}

.resume-section-skills {
    display: flex;
    width: 65%;
    list-style: disc;
}

.resume-skill-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.resume-skill-item {
    font-size: var(--fs-s);
    font-weight: 500;
    text-align: left;
    min-width: 100px;
    margin-bottom: 5px;
}

.resume-projects-container {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
}

.resume-project-item {
    margin-bottom: 20px;
}

.resume-project-name,
.resume-project-github {
    font-size: var(--fs-s);
    margin: 0;
    padding: 0;
}

.resume-project-github {
    font-size: var(--fs-xs);
    font-weight: normal;
    margin-top: 3px;
}

.resume-project-desc {
    font-size: var(--fs-s);
    margin-top: 10px;
    padding: 0;
}