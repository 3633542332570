#Contact {
    padding-bottom: 80px;
}

#contact-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

#contact-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 800px;
}

.contact-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.contact-form-input-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.contact-form-input-wrapper:nth-child(2) {
    margin-left: 40px;
}

.contact-form-input-wrapper h3 {
    margin: 0 0 10px 0;
    padding: 0 0 0 7px;
    font-size: var(--fs-s);
    color: var(--fc-1);
    font-weight: normal;
}

.contact-form-input-wrapper input,
.contact-form-input-wrapper textarea {
    border: 2px solid var(--fc-2);
    background-color: var(--bc-1);
    color: var(--fc-1);
    border-radius: 5px;
    padding: 15px 0 15px 10px;
}

.contact-form-input-wrapper textarea {
    height: auto;
}

#contact-form-submit {
    font-size: var(--fs-m);
    color: var(--fc-1);
    border: 2.2px solid var(--fc-3);
    padding: 12px 40px;
    font-weight: bold;
    width: fit-content;
    background-color: var(--bc-1);
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
}


/*Mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    #contact-content {
        margin: 40px var(--margin-m) 0 var(--margin-m);
        flex: 1;
    }
    #contact-form {
        width: 100%;
    }
    .contact-form-input-wrapper:nth-child(2) {
        margin-left: 20px;
    }
    .contact-form-row {
        margin-bottom: 20px;
        width: 100%;
    }
    .contact-form-input-wrapper {}
    .contact-form-input-wrapper h3 {
        font-size: var(--fs-xs);
        margin: 0 0 7px 0;
        padding: 0 0 0 5px;
    }
    .contact-form-input-wrapper input,
    .contact-form-input-wrapper textarea {
        padding: 10px 0 10px 7px;
        width: 100%;
    }
    #contact-form-submit {
        font-size: var(--fs-s);
        padding: 10px 30px;
    }
}


/*Med */

@media only screen and (max-width: 1200px) and (min-width: 769px) {
    #contact-content {
        margin: 80px var(--margin-dm) 0 var(--margin-dm);
        flex: 1;
    }
    #contact-form {
        width: 100%;
        max-width: 800px;
    }
    .contact-form-row {
        width: 100%;
    }
    .contact-form-input-wrapper input,
    .contact-form-input-wrapper textarea {
        width: 100%;
    }
}