nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: var(--bc-1);
}

nav a {
    text-decoration: none;
    color: var(--fc-1);
    font-size: var(--fs-xs);
    font-weight: bold;
    text-transform: uppercase;
}

.nav-section-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-left: var(--margin-d);
    z-index: 1;
}

.nav-section-links li {
    margin-right: 30px;
}

.nav-section-link {}

.nav-resume-btn {
    margin-right: var(--margin-d);
    z-index: 1;
}

.nav-resume-btn a {
    border: 1px solid var(--fc-1);
    padding: 12px 24px;
    border-radius: 5px;
}

#nav-hamburger {
    display: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#nav-hamburger svg {
    width: 100%;
    height: 100%;
}

#nav-blur {
    position: fixed;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: var(--bc-1);
    z-index: 0;
}

#mobile-nav-resume-link {
    display: none;
}


/*mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    nav{
        background-color: unset;
    }
    .nav-resume-btn,
    .nav-section-links {
        display: none;
    }
    #mobile-nav-links {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex: 1;
        flex-direction: column;
    }
    #mobile-nav-resume-link {
        display: flex;
    }
    .nav-section-links {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: rgb(51 13 100 / 95%);
        backdrop-filter: blur(3px);
    }
    .nav-section-links li {
        display: flex;
        justify-content: center;
        margin: 0;
    }
    nav a {
        font-size: var(--fs-l);
        width: 100%;
        text-align: center;
        padding: 7px 0;
    }
    #nav-hamburger {
        display: flex;
        align-items: center;
        margin-left: var(--margin-m);
        fill: var(--fc-1);
    }
    #nav-blur {
        position: fixed;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: rgb(51 13 100 / 95%);
        z-index: 0;
        backdrop-filter: blur(3px);
    }
}


/*med*/

@media only screen and (max-width: 1200px) and (min-width: 769px) {
    .nav-section-links {
        margin-left: var(--margin-dm);
    }
    .nav-resume-btn {
        margin-right: var(--margin-dm);
    }
}


/*large*/

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
    .nav-section-links {
        margin-left: var(--margin-ld);
    }
    .nav-resume-btn {
        margin-right: var(--margin-ld);
    }
}


/*xlarge*/

@media only screen and (min-width: 2250px) {
    .nav-section-links {
        margin-left: var(--margin-xld);
    }
    .nav-resume-btn {
        margin-right: var(--margin-xld);
    }
}