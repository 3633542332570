footer {
    display: flex;
    flex-direction: row;
    height: 80px;
    background-color: var(--bc-2);
    align-items: center;
    justify-content: center;
}

footer h3 {
    color: var(--grey);
    font-weight: normal;
    font-size: var(--fs-s);
    margin:0;
}

#footer-fork {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#footer-fork svg {
    margin: 0 5px 0 25px;
}

footer a {
    color: var(--grey);
    font-weight: normal;
    font-size: var(--fs-s);
}


/*Mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    footer {
        height: 80px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #footer-fork svg {
        margin:0;
    }
    footer h3{
        margin-bottom: 10px;
    }
    footer h3,
    footer a {
        font-size: var(--fs-xs);
    }
}