#Projects {
    width: 100%;
    padding-bottom: 150px;
    position: relative;
    overflow: hidden;
}

#Projects .section-content {
    flex-direction: column;
    margin: 0;
}

#Projects .section-title {
    width: 100%;
    margin: 0 var(--margin-d);
}


/*Mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    #Projects {
        height: fit-content;
        padding-bottom: 80px;
    }
    #Projects .section-title {
        margin: 0 var(--margin-m);
    }
}


/*Med */

@media only screen and (max-width: 1350px) and (min-width: 769px) {
    #Projects {
        height: fit-content;
        padding-bottom: 80px;
    }
    #Projects .section-title {
        margin: 0 var(--margin-dm);
    }
}


/*large*/

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
    #Projects .section-title {
        margin: 0 var(--margin-ld);
    }
}


/*xlarge*/

@media only screen and (min-width: 2250px) {
    #Projects .section-title {
        margin: 0 var(--margin-xld);
    }
}