:root {
    /*background colors*/
    --bc-1: #330D64;
    --bc-2: #300B5F;
    --bc-3: #250A4A;
    /*font colors*/
    --fc-1: #FDF8DF;
    --fc-2: #FFE16D;
    --fc-3: #3FB1B1;
    --grey: #8B8B8B;
    /*font sizes*/
    --fs-xxl: 80px;
    --fs-xl: 62px;
    --fs-l: 32px;
    --fs-m: 20px;
    --fs-s: 15px;
    --fs-xs: 13px;
    /*margins offsets*/
    --margin-xld: 13%;  /*xlarge desktop*/
    --margin-ld: 300px; /*large desktop*/
    --margin-d: 140px; /*desktop*/
    --margin-dm: 80px; /*desktop med*/
    --margin-m: 25px; /*mobile*/
}

html,
body {
    height: 100%;
    width: 100%;
    background-color: var(--bc-1);
}


/*Global Styles*/
.section-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    margin-top: 80px;
}

.section-title h1 {
    font-size: var(--fs-xl);
    font-weight: bold;
    color: var(--fc-1);
    margin: 0;
    z-index: 1;
}

.section-title hr {
    width: 125px;
    height: 3px;
    background-color: var(--fc-3);
    margin: -8px 0 0 5px;
    border: none;
    z-index: 0;
}

.section-content {
    margin: 0 var(--margin-d);
    display: flex;
    flex: 1;
}


/*Mobile Globals*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    .section-content {
        margin: 0 var(--margin-m);
    }
    .section-title {
        margin-top: 80px;
    }
    .section-title h1 {
        font-size: var(--fs-l);
    }
    .section-title hr {
        width: 63px;
        height: 3px;
        margin: -5px 0 0 2px;
    }
}


/*Med Globals*/

@media only screen and (max-width: 1200px) and (min-width: 769px) {
    .section-content {
        margin: 0 var(--margin-dm);
    }
}


/*large Globals*/

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
    .section-content {
        margin: 0 var(--margin-ld);
    }
}


/*xlarge Globals*/

@media only screen and (min-width: 2250px) {
    .section-content {
        margin: 0 var(--margin-xld);
    }
}