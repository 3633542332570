#Home {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}
#SuperRadFirstName{
    text-transform: capitalize;
}
.home-left {
    display: flex;
    flex-direction: column;
    margin-left: var(--margin-d);
    flex: 1;
    z-index: 1;
}

.home-right {
    margin-right: var(--margin-d);
    flex: 1;
    z-index: 1;
}

#home-head-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-title {
    margin: 0;
    padding: 0;
}

.home-head {
    color: var(--fc-1);
    font-size: var(--fs-xxl);
}

.home-subhead {
    color: var(--fc-2);
    font-size: var(--fs-xl);
}

.home-body {
    color: var(--fc-1);
    font-size: var(--fs-m);
    margin-top: 20px;
}

#home-divider {
    width: 118%;
    height: 126%;
    position: absolute;
    top: 42%;
    left: -28%;
    background-color: var(--bc-2);
    z-index: 0;
    transform: rotate(53deg);
}


/*mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    #Home {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding-bottom: 80px;
    }
    .home-left {
        margin-left: var(--margin-m);
        margin-right: var(--margin-m);
        flex: 1;
        align-items: center;
    }
    .home-right {
        width: 100%;
        margin: 0;
        margin-top: 100px;
        margin-bottom: 30px;
        flex: 1 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
    }
    .home-head {
        font-size: var(--fs-xl);
    }
    .home-subhead {
        font-size: var(--fs-l);
        text-align: center;
    }
    .home-body {
        font-size: var(--fs-s);
        text-align: center;
    }
    #home-divider {
        left: 0;
        width: 100%;
        height: 65%;
        top: unset;
        transform: rotate(0deg);
        bottom: 0;
    }
}


/*med*/


/* @media only screen and (max-width: 1350px) and (min-width: 769px) {} */

@media only screen and (max-width: 1200px) and (min-width: 769px) {
    #Home {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding-bottom: 80px;
    }
    .home-left {
        margin-left: var(--margin-dm);
        margin-right: var(--margin-dm);
        flex: 1;
        align-items: center;
    }
    .home-right {
        width: 100%;
        margin: 0;
        margin-top: 100px;
        margin-bottom: 40px;
        flex: 1 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
    }
    .home-subhead {
        text-align: center;
    }
    .home-body {
        text-align: center;
    }
    #home-divider {
        left: 0;
        width: 100%;
        height: 65%;
        top: unset;
        transform: rotate(0deg);
        bottom: 0;
    }
}


/*large*/

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
    .home-left {
        margin-left: var(--margin-ld);
    }
    .home-right {
        margin-right: var(--margin-ld);
    }
}


/*xlarge*/

@media only screen and (min-width: 2250px) {
    .home-left {
        margin-left: var(--margin-xld);
    }
    .home-right {
        margin-right: var(--margin-xld);
    }
}