.project {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
}

.project:nth-child(odd),
.project:nth-child(odd)>.project-left {
    flex-direction: row-reverse;
}

.project:nth-child(odd)>.project-divider {
    left: 0%;
}

.project-left,
.project-right {
    z-index: 1;
    width: 48%;
}

.project-left {
    display: flex;
    margin-left: var(--margin-d);
    padding: 50px 50px 50px 0;
}

.project-right {
    display: flex;
    margin-right: var(--margin-d);
    padding: 50px 0px 50px 0;
}

.project:nth-child(odd)>.project-left {
    margin-right: var(--margin-d);
    margin-left: 0;
    padding: 50px 0px 50px 50px;
}

.project:nth-child(odd)>.project-right {
    margin-right: 0;
    margin-left: var(--margin-d);
    padding: 50px 50px 50px 0px;
}

.project-preview {
    width: 700px;
    height: 400px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-preview img {
    height: 100%;
    width: auto;
}

.project-preview-blank {
    background-color: var(--fc-1);
}

.project-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.project-title {
    font-size: var(--fs-l);
    color: var(--fc-2);
    margin: 0 0 35px 0;
    padding: 0;
}

.project-text {
    font-size: var(--fs-m);
    color: var(--fc-1);
    margin: 0 0 35px 0;
    padding: 0;
}

.project-details hr {
    background-color: var(--fc-2);
    border: 0;
    height: 3px;
    width: 45px;
    margin: 0 0 35px 0;
}

.project-links {
    padding: 10px 0px;
}

.project-links a:first-child {
    margin-right: 30px;
}

.project-links a {
    padding: 10px 20px;
    border-radius: 5px;
    color: var(--fc-3);
    border: 1.5px solid var(--fc-3);
    text-decoration: none;
    font-size: var(--fs-m);
    font-weight: bold;
}

.project-divider {
    height: 100%;
    width: 65%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background-color: var(--bc-2);
}


/*Mobile*/

@media only screen and (max-width: 768px) and (min-width: 0px) {
    .project {
        flex-direction: column!important;
        margin-top: 40px;
        padding-bottom: 80px;
    }
    .project-left,
    .project-right {
        flex: 1;
        padding: 0!important;
        width: 100%;
        margin: 0!important;
    }
    .project-preview,
    .project-details {
        margin-left: var(--margin-m);
        margin-right: var(--margin-m);
    }
    .project-preview {
        width: 100%;
        height: 300px;
    }
    .project-details {
        margin-top: 20px;
    }
    .project-title {
        font-size: var(--fs-m);
        margin: 0 0 20px 0;
    }
    .project-text {
        font-size: var(--fs-s);
        margin: 0 0 20px 0;
    }
    .project-details hr {
        margin: 0 0 20px 0;
    }
    .project-links a {
        font-size: var(--fs-s);
    }
    .project-divider {
        width: 100%;
        bottom: 0;
        left: 0;
        top: unset;
        right: unset;
        height: 65%;
    }
}


/*Med */

@media only screen and (max-width: 1350px) and (min-width: 769px) {
    .project {
        flex-direction: column!important;
        padding-bottom: 80px;
    }
    .project-left,
    .project-right {
        flex: 1;
        padding: 0!important;
        width: 100%;
        margin: 0!important;
        flex-direction: column!important;
        justify-content: center;
        align-items: center;
    }
    .project-preview,
    .project-details {
        margin-left: var(--margin-dm);
        margin-right: var(--margin-dm);
    }
    .project-preview,
    .project-details {
        width: 55%;
    }
    .project-preview {
        height: 33vw;
    }
    .project-details {
        margin-top: 20px;
    }
    .project-title {
        margin: 0 0 20px 0;
    }
    .project-text {
        font-size: var(--fs-s);
        margin: 0 0 20px 0;
        width: 80%;
    }
    .project-details hr {
        margin: 0 0 20px 0;
    }
    .project-links a {
        font-size: var(--fs-s);
    }
    .project-divider {
        width: 100%;
        bottom: 0;
        left: 0;
        top: unset;
        right: unset;
        height: 75%;
    }
}


/*Med to Large Fixes */

@media only screen and (min-width: 1350px) and (max-width: 1700px) {}


/*large*/

@media only screen and (min-width: 2000px) and (max-width: 2250px) {
    .project {
        margin-top: 0;
        padding: 40px 0;
    }
    .project:nth-child(odd) {
        background-color: var(--bc-2);
    }
    .project-left {
        margin-left: var(--margin-ld);
    }
    .project-right {
        margin-right: var(--margin-ld);
    }
    .project:nth-child(odd)>.project-left {
        margin-right: var(--margin-ld);
    }
    .project:nth-child(odd)>.project-right {
        margin-left: var(--margin-ld);
    }
    .project-divider {
        display: none;
    }
}


/*xlarge*/

@media only screen and (min-width: 2250px) {
    .project {
        margin-top: 0;
        padding: 40px 0;
    }
    .project:nth-child(odd) {
        background-color: var(--bc-2);
    }
    .project-left {
        margin-left: var(--margin-xld);
    }
    .project-right {
        margin-right: var(--margin-xld);
    }
    .project:nth-child(odd)>.project-left {
        margin-right: var(--margin-xld);
    }
    .project:nth-child(odd)>.project-right {
        margin-left: var(--margin-xld);
    }
    .project-divider {
        display: none;
    }
}