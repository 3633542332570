#Experience {
    width: 100%;
    display: flex;
    height: fit-content;
    padding-bottom: 100px;
}

#Experience .section-content {
    flex-direction: column;
}

#experience-right {
    display: flex;
}

#experience-left {
    display: flex;
}

#experience-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 75%;
    align-self: center;
    height: 100%;
    margin-top: 67px;
}

#experience-controls {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-right: 80px;
}

#experience-controls li {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
}

#experience-controls li a {
    text-decoration: none;
    color: var(--fc-1);
    font-size: var(--fs-m);
}

#experience-controls-selected {
    font-weight: bold;
}

#experience-controls-selected::before {
    width: 2px;
    height: 20px;
    background-color: var(--fc-3);
    content: "";
    margin-right: 10px;
    border-radius: 50%;
}

#experience-details {
    display: flex;
    flex-direction: column;
}

#experience-details h2 {
    color: var(--fc-2);
    font-weight: 500;
    font-size: var(--fs-l);
    margin: 0;
    padding: 0;
}

#experience-details-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

#experience-details-title h2 {
    margin-right: 110px;
    text-align: center
}

#experience-details-title p {
    margin: 0;
}

#experience-details-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

#experience-details-list li {
    margin-bottom: 10px;
}

#experience-details-skills {
    margin-top: 40px;
}

#experience-details-skills h2 {
    margin-bottom: 30px;
}

.experience-details-text {
    color: var(--fc-1);
    font-weight: normal;
    font-size: var(--fs-m);
    margin: 0 0 0 90px;
    padding: 0;
}


/*Mobile */

@media only screen and (max-width: 768px) and (min-width: 0px) {
    #Experience {
        height: fit-content;
        padding-bottom: 80px;
    }
    #experience-content {
        width: 100%;
        margin-top: 0;
        flex-direction: column;
    }
    #experience-left,
    #experience-right {
        width: 100%;
    }
    #experience-controls {
        margin: 40px 0 0 0;
        flex-direction: row;
        width: 100%;
    }
    #experience-controls li {
        display: flex;
        flex: 1;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        padding-bottom: 7px;
        border-bottom: 2px solid var(--bc-3);
    }
    #experience-controls li a {
        font-size: var(--fs-s);
    }
    #experience-controls-selected::before {
        width: 0;
        height: 0;
        margin-right: 0;
    }
    #experience-controls #experience-controls-selected {
        border-bottom: 2px solid var(--fc-3);
    }
    #experience-details {
        margin: 0;
        width: 100%;
    }
    #experience-details-title {
        margin-top: 40px;
        justify-content: space-between;
        text-align: center;
    }
    #experience-details h2 {
        font-size: var(--fs-m);
        margin-right: 0;
    }
    .experience-details-text {
        font-size: var(--fs-xs);
        margin: 0 0 0 30px;
    }
}


/*Med */

@media only screen and (max-width: 1200px) and (min-width: 769px) {
    #Experience {}
    #experience-content {
        width: 100%;
        flex: 1;
    }
    #experience-controls {
        margin: 0;
    }
    #experience-details {
        margin: 0;
        flex: 1;
        margin-left: 40px;
    }
    #experience-left {
        align-items: center;
    }
    #experience-details-title h2 {
        margin-right: 0;
        padding-right: 20px;
    }
    .experience-details-text {
        font-size: var(--fs-s);
        margin: 0 0 0 50px;
    }
}